<template>
  <div>
    <div class="pc-container d-none d-sm-block">
      <div class="banner">
        <img width="100%" height="auto" src="@/assets/images/team_banner.png" class="d-block" />
        <div class="content">
          <div class="tit white--text">{{ $t('Team.Title') }}</div>
          <div class="mt-1 mt-sm-3 white--text" v-html="$t('Team.Desc')"></div>
        </div>
      </div>
      <div class="about">
        <div class="subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitA.title') }}</div>
        <v-row>
          <v-col cols="12" lg="5" md="6" sm="12"><v-card elevation="0" class="mt-15 px-6 px-md-10 py-8">
              <v-card height="360px" elevation="0" color="#FBF9F6"
                class="d-flex flex-column justify-start align-center py-8">
                <!-- <div class="text-h5">{{ `${$t('Team.SubtitA.name')}(${$t('Team.SubtitA.dname')})` }}</div>
                <v-avatar class="mt-4" size="150px">
                  <img src="@/assets/images/avatar3.jpg" class="d-block" style="max-width:520px" />
                </v-avatar>
                <div class="d-flex justify-center align-center  my-8">
                  <a href="https://twitter.com/OshimaNoboru" target="_blank" class="d-block">
                    <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
                  </a>
                  <a href="https://www.linkedin.cn/incareer/in/%E5%B3%B6%E6%98%87-%E5%A4%A7-289b68265/" target="_blank"
                    class="d-block ml-10">
                    <v-img contain width="40" src="@/assets/images/Linkedin.svg"></v-img>
                  </a>
                </div> -->
                <!-- <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Team.SubtitA.deeds1') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds2') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds3') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds4') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds5') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds6') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds7') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds8') }}</div>
            </div> -->
              </v-card>
            </v-card></v-col>
          <v-col cols="12" lg="5" md="6" sm="12"><v-card elevation="0" class="mt-8 mt-md-15 px-6 px-md-10 py-8">
              <v-card height="360px" elevation="0" color="#FBF9F6"
                class="d-flex flex-column justify-start align-center py-8">
                <!-- <div class="text-h5">{{ `${$t('Team.SubtitA.name2')}(${$t('Team.SubtitA.cname')})` }}</div>
                <v-avatar class="mt-4" size="150px">
                  <img src="../../assets/images/avatar5.jpg" class="d-block" style="max-width:520px" />
                </v-avatar>
                <div class="d-flex justify-center align-center  my-8">
                  <a href="https://twitter.com/MurakamiSatoru_" target="_blank" class="d-block">
                    <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
                  </a>
                  <a href="https://www.linkedin.com/in/上聡-村-5709b2265" target="_blank" class="d-block ml-10">
                    <v-img contain width="40" src="@/assets/images/Linkedin.svg"></v-img>
                  </a>
                </div> -->
                <!-- <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Team.SubtitA.deeds9') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds10') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds11') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds12') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds13') }}</div>
            </div> -->
              </v-card>
            </v-card></v-col>
        </v-row>

        <div class="mt-4 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitB.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 ">
          <div class="mt-2">{{ $t('Team.SubtitB.con') }}</div>
        </v-card>

        <div class="mt-10 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitC.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8">
          <div class="mt-2">{{ $t('Team.SubtitC.con') }}</div>
        </v-card>

        <div class="mt-10 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitD.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 pr-6">
          <div class="mt-2">{{ $t('Team.SubtitD.con') }}</div>
        </v-card>

        <div class="mt-10 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitE.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 pr-6">
          <div class="mt-2">{{ $t('Team.SubtitE.con') }}</div>
        </v-card>
      </div>
    </div>

    <!-- mobile -->
    <div class="mobile-container d-block d-sm-none">
      <div class="banner">
        <img width="100%" height="300px" src="@/assets/images/team_banner.png" class="d-block" />
        <div class="content px-6">
          <div class="tit white--text">{{ $t('Team.Title') }}</div>
          <div class="mt-1 mt-sm-3 white--text" v-html="$t('Team.Desc')"></div>
        </div>
      </div>
      <div class="about">
        <div class="subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitA.title') }}</div>
        <v-row>
          <v-col cols="12" lg="5" md="6" sm="12"><v-card elevation="0" class="mt-10 px-6 px-md-10 py-4">
              <v-card height="360px" elevation="0" color="#FBF9F6"
                class="d-flex flex-column justify-start align-center py-8">
                <!-- <div class="text-h5">{{ `${$t('Team.SubtitA.name')}(${$t('Team.SubtitA.dname')})` }}</div>
            <v-avatar class="mt-4" size="150px">
              <img src="@/assets/images/avatar3.jpg" class="d-block" style="max-width:520px" />
            </v-avatar>
            <div class="d-flex justify-center align-center  my-8">
                <a href="https://twitter.com/OshimaNoboru" target="_blank" class="d-block">
                    <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
                </a>
                <a href="https://www.linkedin.cn/incareer/in/%E5%B3%B6%E6%98%87-%E5%A4%A7-289b68265/" target="_blank" class="d-block ml-10">
                    <v-img contain width="40" src="@/assets/images/Linkedin.svg"></v-img>
                </a>
            </div> -->
                <!-- <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Team.SubtitA.deeds1') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds2') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds3') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds4') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds5') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds6') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds7') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds8') }}</div>
            </div> -->
              </v-card>
            </v-card></v-col>
          <v-col cols="12" lg="5" md="6" sm="12"><v-card elevation="0" class="mt-10 mt-md-10 px-6 px-md-10 py-4">
              <v-card height="360px" elevation="0" color="#FBF9F6"
                class="d-flex flex-column justify-start align-center py-8">
                <!-- <div class="text-h5">{{ `${$t('Team.SubtitA.name2')}(${$t('Team.SubtitA.cname')})` }}</div>
                <v-avatar class="mt-4" size="150px">
                  <img src="../../assets/images/avatar5.jpg" class="d-block" style="max-width:520px" />
                </v-avatar>
                <div class="d-flex justify-center align-center  my-8">
                  <a href="https://twitter.com/MurakamiSatoru_" target="_blank" class="d-block">
                    <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
                  </a>
                  <a href="https://www.linkedin.com/in/上聡-村-5709b2265" target="_blank" class="d-block ml-10">
                    <v-img contain width="40" src="@/assets/images/Linkedin.svg"></v-img>
                  </a>
                </div> -->
                <!-- <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Team.SubtitA.deeds9') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds10') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds11') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds12') }}</div>
              <div class="mt-2">{{ $t('Team.SubtitA.deeds13') }}</div>
            </div> -->
              </v-card>
            </v-card></v-col>
        </v-row>

        <div class="mt-4 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitB.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 ">
          <div class="mt-2">{{ $t('Team.SubtitB.con') }}</div>
        </v-card>

        <div class="mt-10 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitC.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8">
          <div class="mt-2">{{ $t('Team.SubtitC.con') }}</div>
        </v-card>

        <div class="mt-10 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitD.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 pr-6">
          <div class="mt-2">{{ $t('Team.SubtitD.con') }}</div>
        </v-card>

        <div class="mt-10 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Team.SubtitE.title') }}</div>
        <v-card elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 pr-6">
          <div class="mt-2">{{ $t('Team.SubtitE.con') }}</div>
        </v-card>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/Footer.vue'

export default {
  components: {
    Footer
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.banner {
  position: relative;

  .content {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    font-size: 18px;

    .tit {
      font-size: 56px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
    }
  }
}

.about {
  padding: 80px 120px;

  .subtit {
    position: relative;
  }

  .subtit::before {
    content: '';
    position: absolute;
    width: 60%;
    height: 7px;
    background: #ac8250;
    bottom: -20px;
    left: 0;
  }
}

@media screen and (max-width: 960px) {
  .banner {

    .content {
      font-size: 14px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      .tit {
        font-size: 38px;
      }
    }
  }

  .about {
    padding: 30px 16px;
  }

  .twitter,
  .medium,
  .discord {
    width: 30px !important;
  }
}
</style>